<template>
  <div class="table-component" :class="{ compact: compact }">
    <table class="w-full">
      <thead>
        <tr class="bg-grey-header rounded-lg border-neutral-400 border-x-2">
          <td v-for="(column, index) in columns" :key="'columns-' + index" class="whitespace-nowrap text-neutral font-bold" :class="{ 'text-center': column.center }">
            {{ sliceString(column.label) }}
          </td>
        </tr>
      </thead>
      <draggable v-model="computedList" tag="tbody" class="tbody" :disabled="!drag" @change="handleChange" v-if="list.length !== 0 && drag === true">
        <tr v-for="(entry, idx) in computedList" :key="'entry-' + idx">
          <td v-for="(column, index) in columns" :key="'columns-' + index" class="align-top leading-5">
            <slot :entry="entry" :column="column" :index="idx" />
          </td>
        </tr>
      </draggable>
      <tbody class="tbody" v-if="list.length !== 0 && drag === false">
        <tr v-for="(entry, idx) in list" :key="'entry-' + idx">
          <td v-for="(column, index) in columns" :key="'columns-' + index" class="align-top leading-5">
            <slot :entry="entry" :column="column" :index="idx" />
          </td>
        </tr>
      </tbody>
      <tbody class="tbody" v-if="list.length === 0">
        <tr>
          <td :colspan="columns.length" class="text-center h-80">Data is Empty</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'general-table',
  components: {
    draggable
  },
  props: {
    columns: { type: Array, default: () => [] },
    list: { type: Array, default: () => [] },
    drag: { type: Boolean, default: false },
    compact: { type: Boolean, default: true }
  },
  data() {
    return {
      activeNames: ''
    }
  },
  computed: {
    computedList: {
      get() {
        return this.list
      },
      set(newValue) {
        this.$emit('handleMutateTable', newValue)
      }
    },
    selectedList() {
      return this.drag ? this.computedList : this.list
    }
  },
  methods: {
    handleChange(evt) {
      this.$emit('handleChange', evt)
    },
    sliceString(str) {
      if (!str) return str
      if (str.length < 20) return str
      return str.slice(0, 19) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
.table-component {
  min-height: 380px;
  border: 1px solid #c2c2c2;
  overflow-y: auto;
  thead {
    tr {
      // border-radius: 50px;
      // border-top-width: 1px;
      // border-left-width: 1px;
      // border-right-width: 1px;
      // border-color: #C2C2C2;
      td {
        color: #333333;
        font-weight: 700;
        padding: 15px 8px;
      }
    }
  }
  .tbody {
    tr {
      // border-bottom-radius: 50px;
      // border-bottom-width: 1px;
      // border-left-width: 1px;
      // border-right-width: 1px;
      // border-color: #C2C2C2;
      td {
        font-size: 14px;
        background-color: #fff;
        padding: 12px 8px;
        box-shadow: inset 0px -1px 0px rgba(206, 213, 222, 0.5);
      }
      &:last-child td {
        box-shadow: none;
      }
    }
  }
}
// .row {
//   &-item {
//     &:last-child {
//       .shadow-border-b {
//         // box-shadow: none;
//       }
//     }
//   }
// }
.compact {
  min-height: 0 !important;
}
</style>