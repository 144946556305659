var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-component",
    class: {
      compact: _vm.compact
    }
  }, [_c('table', {
    staticClass: "w-full"
  }, [_c('thead', [_c('tr', {
    staticClass: "bg-grey-header rounded-lg border-neutral-400 border-x-2"
  }, _vm._l(_vm.columns, function (column, index) {
    return _c('td', {
      key: 'columns-' + index,
      staticClass: "whitespace-nowrap text-neutral font-bold",
      class: {
        'text-center': column.center
      }
    }, [_vm._v(" " + _vm._s(_vm.sliceString(column.label)) + " ")]);
  }), 0)]), _vm.list.length !== 0 && _vm.drag === true ? _c('draggable', {
    staticClass: "tbody",
    attrs: {
      "tag": "tbody",
      "disabled": !_vm.drag
    },
    on: {
      "change": _vm.handleChange
    },
    model: {
      value: _vm.computedList,
      callback: function callback($$v) {
        _vm.computedList = $$v;
      },
      expression: "computedList"
    }
  }, _vm._l(_vm.computedList, function (entry, idx) {
    return _c('tr', {
      key: 'entry-' + idx
    }, _vm._l(_vm.columns, function (column, index) {
      return _c('td', {
        key: 'columns-' + index,
        staticClass: "align-top leading-5"
      }, [_vm._t("default", null, {
        "entry": entry,
        "column": column,
        "index": idx
      })], 2);
    }), 0);
  }), 0) : _vm._e(), _vm.list.length !== 0 && _vm.drag === false ? _c('tbody', {
    staticClass: "tbody"
  }, _vm._l(_vm.list, function (entry, idx) {
    return _c('tr', {
      key: 'entry-' + idx
    }, _vm._l(_vm.columns, function (column, index) {
      return _c('td', {
        key: 'columns-' + index,
        staticClass: "align-top leading-5"
      }, [_vm._t("default", null, {
        "entry": entry,
        "column": column,
        "index": idx
      })], 2);
    }), 0);
  }), 0) : _vm._e(), _vm.list.length === 0 ? _c('tbody', {
    staticClass: "tbody"
  }, [_c('tr', [_c('td', {
    staticClass: "text-center h-80",
    attrs: {
      "colspan": _vm.columns.length
    }
  }, [_vm._v("Data is Empty")])])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }